import React from "react";
import Form from "antd/lib/form";
import { _t } from "../../Common/components/InjectIntlContext";
import { useSendContacts } from "../apiHooks";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import Input from "antd/lib/input";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import CustomButton from "../../Common/components/CustomButton";
import { Typography } from "antd";

const Contacts = () => {
  const [SendContacts, { isLoading }] = useSendContacts();
  const recaptchaRef = React.createRef();
  const locale = getLocale();

  return (
    <section className="contact main-wrap--inner custom-section custom-section--no-indent">
      <div className="container">
        <h2 className="custom-section__title" style={{ textAlign: "center" }}>
          {_t("get_in_touch")}
        </h2>
        <p className="contact__text">{_t("contacts_welcome_message")}</p>

        <Form
          action={APP_ROUTES.contacts}
          name="contacts_form"
          className="contact__form"
          method="post"
          onFinish={SendContacts}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: _t("input_name"),
              },
            ]}
          >
            <Input placeholder={_t("Name")} />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: _t("input_email"),
              },
              {
                type: "email",
                message: _t("not_valid_email_message"),
              },
            ]}
          >
            <Input placeholder={_t("Email")} />
          </Form.Item>

          <Form.Item
            name="text"
            className="textarea-wrap"
            rules={[
              {
                required: true,
                message: _t("Please input details"),
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder={_t("your_message")} />
          </Form.Item>
          <Form.Item
            name="g-recaptcha-response"
            rules={[
              {
                required: true,
                message: _t("recaptcha_required"),
              },
            ]}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={config.google_recaptcha_sitekey}
              className={"recaptcha"}
              hl={locale}
            />
          </Form.Item>
          <CustomButton
            type="submit"
            disabled={isLoading}
            btnLabel={_t("Send")}
          />
        </Form>
      </div>
    </section>
  );
};

export default Contacts;
