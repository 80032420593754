import React from "react";

export const currencyFormator = new Intl.NumberFormat("ru-RU", {
  useGrouping: true,
});

export function CurrencyLabel({ value, pure = false }) {
  let numVal = parseFloat(value || 0);

  if (!pure) {
    numVal = numVal / 100;
  }

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <span className={value >= 0 ? "currency-green" : "currency-red"}>
        &euro;
      </span>{" "}
      {currencyFormator.format(numVal)}
    </span>
  );
}
