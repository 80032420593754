import React, { useState } from "react";
import { FaqResponse } from "../../api";
import { QA } from "../QA/QA";
import { Spin } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";

type FaqsProps = {
  data: FaqResponse;
  loading: boolean;
};

export function Faqs({ data, loading }: FaqsProps) {
  return (
    <Spin spinning={loading}>
      <div className="faq-item-grid">
        {data?.faq?.map((item) => (
          <div className="faq-item-grid__item">
            <QA faq={item} key={item.id} />
          </div>
        ))}
      </div>
    </Spin>
  );
}
