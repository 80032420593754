import React, { useState } from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useFaqsList } from "../apiHooks";
import { FaqTitles } from "../components/FaqTitles/FaqTitles";
import { getLocale } from "../../../system/helpers/localstorageHelper";

const Faq = () => {
  const [filter, setFilter] = useState("general");
  const locale = getLocale();
  const { data, isLoading } = useFaqsList({
    category: filter,
    language: locale,
  });

  return (
    <LandingTemplate>
      <FaqTitles data={data} loading={isLoading} setFilter={setFilter} />
    </LandingTemplate>
  );
};

export default Faq;
