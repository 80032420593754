import React from "react";
import { ColumnType } from "antd/lib/table";
import { Report } from "../Dashboard/api";
import { renderCurrency } from "../Dashboard/constants";
import { APP_ROUTES } from "../../system/constants/constantsUrl";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Input } from "antd";
import { SelectInput } from "../Common/components";

export const dateKey = "reports.date";
export const AffiliatesReportQuery = "AffiliatesReportQuery";
export const visibleAffiliatesReportColumnsKey =
  "AffiliatesReportVisibleColumns";
export const AffiliatesReportUserStatusesQuery =
  "AffiliatesReportUserStatusesQuery";
export const AffiliatesReportProgramsQuery = "AffiliatesReportProgramsQuery";

export interface AffiliatesReportRequest {
  from: string;
  to: string;
  reg_date_from?: string;
  reg_date_to?: string;
  page_size?: number;
  promos?: string;
  export?: string;
  login?: string;
  balance?: string;
  status?: string;
  role?: string;
  traffic_type?: string;
  cash_type?: string;
  hits?: string;
  hosts?: string;
  regs?: string;
  rounds?: string;
  qualified_players?: string;
  first_deposits?: string;
  deposits?: string;
  withdraws?: string;
  ng?: string;
  comppoints?: string;
  casino_profit?: string;
  partner_income?: string;
}

export interface AffiliatesReportReport {
  partner_id: number;
  ng: string;
  deposits: string;
  withdraws: string;
  cancels: string;
  comppoints: string;
  partner_income: string;
  casino_income: string;
  hold: string;
  hits: string;
  hosts: string;
  regs_all: string;
  regs: string;
  installs: string;
  first_deposits: string;
  qualified_players: string;
  gamers: string;
  rounds: string;
  bonus: string;
  casino_profit: string;
  partner_login: string;
  status: string;
  role: string;
  balance: string;
  reg_date: string;
  cash_type: string;
  language: string;
  subscription_language: string;
  traffic_type: string;
  ex_ussr: string;
  status_label: string;
  role_label: string;
  traffic_type_label: string;
  cash_type_label: string;
  payouts: string;
}

export interface Models {
  current_page: number;
  data: AffiliatesReportReport[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

export interface AffiliatesReportResponse {
  models: Models;
}

const allColumnsMap: Record<
  keyof Omit<AffiliatesReportReport, "cancels" | "hold" | "bonus" | "ex_ussr">,
  ColumnType<any>
> = {
  hits: {
    title: "Hits",
    dataIndex: "hits",
  },
  hosts: {
    title: "Hosts",
    dataIndex: "hosts",
  },
  installs: {
    title: "Installs",
    dataIndex: "installs",
  },
  regs: {
    title: "Reg.",
    dataIndex: "regs",
  },
  regs_all: {
    title: "Reg.All",
    dataIndex: "regs_all",
  },
  gamers: {
    title: "Gamers",
    dataIndex: "gamers",
  },
  rounds: {
    title: "Rounds",
    dataIndex: "rounds",
  },
  first_deposits: {
    title: "1st depositors",
    dataIndex: "first_deposits",
  },
  deposits: {
    title: "Deposits",
    dataIndex: "deposits",
    render: renderCurrency,
  },
  withdraws: {
    title: "Withdraws",
    dataIndex: "withdraws",
    render: renderCurrency,
  },
  qualified_players: {
    title: "Qualified players",
    dataIndex: "qualified_players",
  },
  ng: {
    title: "Net Gaming",
    dataIndex: "ng",
    render: renderCurrency,
  },
  partner_income: {
    title: "partner_income",
    dataIndex: "partner_income",
    render: renderCurrency,
  },
  comppoints: {
    title: "Bonus",
    dataIndex: "comppoints",
    render: renderCurrency,
  },
  reg_date: {
    title: "Reg Date",
    dataIndex: "reg_date",
    render: (v) => <div>{moment(v).format("YYYY-MM-DD")}</div>,
  },
  partner_id: {
    title: "partner_id",
    dataIndex: "partner_id",
    render: (value) => {
      return (
        <NavLink
          to={APP_ROUTES.manage.user_profile.replace(":partnerId", value)}
        >
          {value}
        </NavLink>
      );
    },
  },
  partner_login: {
    title: "Login",
    dataIndex: "partner_login",
  },
  status_label: {
    title: "status",
    dataIndex: "status_label",
  },
  role_label: {
    title: "role",
    dataIndex: "role_label",
    render: (v) => <div dangerouslySetInnerHTML={{ __html: v }}></div>,
  },
  traffic_type_label: {
    title: "traffic_type",
    dataIndex: "traffic_type_label",
  },
  cash_type_label: {
    title: "cash_type",
    dataIndex: "cash_type_label",
  },
  balance: {
    title: "balance",
    dataIndex: "balance",
    render: renderCurrency,
  },
  casino_income: {
    title: "casino_income",
    dataIndex: "casino_income",
    render: renderCurrency,
  },
  casino_profit: {
    title: "casino_profit",
    dataIndex: "casino_profit",
    render: renderCurrency,
  },
  status: {
    title: "status",
    dataIndex: "status",
  },
  role: {
    title: "role",
    dataIndex: "role",
  },
  cash_type: {
    title: "cash_type",
    dataIndex: "cash_type",
  },
  language: {
    title: "language",
    dataIndex: "language",
  },
  subscription_language: {
    title: "subscription_language",
    dataIndex: "subscription_language",
  },
  traffic_type: {
    title: "traffic_type",
    dataIndex: "traffic_type",
  },
  payouts: {
    title: "payouts",
    dataIndex: "payouts",
    render: renderCurrency,
  },
};

export const pickColumns = (keys: Array<keyof typeof allColumnsMap>) =>
  keys.map((key) => allColumnsMap[key]);

export const allAffiliateReportsPageColumns: ColumnType<
  Report
>[] = pickColumns([
  "partner_id",
  "partner_login",
  "status_label",
  "role_label",
  "traffic_type_label",
  "reg_date",
  "cash_type_label",
  "balance",
  "hits",
  "hosts",
  "regs",
  "rounds",
  "qualified_players",
  "first_deposits",
  "deposits",
  "withdraws",
  "ng",
  "comppoints",
  "casino_profit",
  "partner_income",
  "payouts",
]);

export type ColumnOption = {
  label: string;
  value: keyof AffiliatesReportReport;
  hidden?: boolean;
};

export const columnOptions: Array<ColumnOption> = [
  {
    label: "id",
    value: "partner_id",
  },
  {
    label: "Login",
    value: "partner_login",
  },
  {
    label: "status",
    value: "status_label",
  },
  {
    label: "role",
    value: "role_label",
  },
  {
    label: "traffic_type",
    value: "traffic_type_label",
  },
  {
    label: "reg_date",
    value: "reg_date",
  },
  {
    label: "cash_type",
    value: "cash_type_label",
  },
  {
    label: "balance",
    value: "balance",
  },
  {
    label: "Hits",
    value: "hits",
  },
  {
    label: "Hosts",
    value: "hosts",
  },
  {
    label: "Reg.",
    value: "regs",
  },
  {
    label: "Rounds",
    value: "rounds",
    hidden: true,
  },
  {
    label: "Qualified players",
    value: "qualified_players",
  },
  {
    label: "1st depositors",
    value: "first_deposits",
  },
  {
    label: "Deposits",
    value: "deposits",
  },
  {
    label: "Withdraws",
    value: "withdraws",
  },
  {
    label: "Net Gaming",
    value: "ng",
  },
  {
    label: "casino_profit",
    value: "casino_profit",
  },
  {
    label: "bonus",
    value: "comppoints",
  },
  {
    label: "partner_income",
    value: "partner_income",
  },
  {
    label: "payouts",
    value: "payouts",
  },
];

export const subtotalColumns = [
  "hits",
  "hosts",
  "regs",
  "withdraws",
  "deposits",
  "ng",
  "bonus",
  "gamers",
  "rounds",
  "full_income",
  "comppoints",
  "partner_income",
  "net_gaming",
  "ltv",
  "full_profit",
  "first_deposits",
  "qualified_players",
  "landing_hits",
  "landing_hosts",
  "balance",
  "casino_profit",
  "payouts",
];

export const affiliateReportColumns: ColumnType<
  AffiliatesReportReport
>[] = columnOptions.map((field) => ({
  title: field.label,
  dataIndex: field.value,
}));

export const groupOptions = (_t: any) => [
  { value: "day", label: _t("Days") },
  { value: "total", label: _t("total") },
];

export const perPageOptions = (_t: any) => [
  { value: "100", label: "100" },
  { value: "500", label: "500" },
  { value: "1000", label: "1000" },
];

export const affiliateReportConfig = (params: any) => ({
  partner_login: {
    searchField: "login",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: "Search...",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  balance: {
    searchField: "balance",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  status_label: {
    searchField: "status",
    component: SelectInput,
    sorter: true,
    options: (optionParams: any) => ({
      value: optionParams.selectedKeys,
      mode: "multiple",
      placeholder: "",
      options: Object.keys(params.userStatuses)?.map((key, index) => ({
        label: params.userStatuses[key],
        value: key,
      })),
      onChange: (value, option) => {
        optionParams.setSelectedKeys(value ?? []);
      },
      onDropdownVisibleChange: (visible) => {
        if (!visible) {
          optionParams.handleSearch(
            optionParams.selectedKeys,
            optionParams.confirm,
            optionParams.dataIndex,
            optionParams.searchProp
          );
        }
      },
    }),
  },
  role_label: {
    searchField: "role",
    component: SelectInput,
    sorter: true,
    options: (optionParams: any) => ({
      mode: "multiple",
      placeholder: "",
      value: optionParams.selectedKeys,
      options: Object.keys(params.userRoles)?.map((key, index) => ({
        label: params.userRoles[key],
        value: key,
      })),
      onChange: (value, option) => {
        optionParams.setSelectedKeys(value ?? []);
      },
      onDropdownVisibleChange: (visible) => {
        if (!visible) {
          optionParams.handleSearch(
            optionParams.selectedKeys,
            optionParams.confirm,
            optionParams.dataIndex,
            optionParams.searchProp
          );
        }
      },
    }),
  },
  traffic_type_label: {
    searchField: "traffic_type",
    component: SelectInput,
    sorter: true,
    options: (optionParams: any) => ({
      mode: "multiple",
      placeholder: "",
      value: optionParams.selectedKeys,
      options: Object.keys(params.trafficTypes)?.map((key, index) => ({
        label: params.trafficTypes[key],
        value: key,
      })),
      onChange: (value, option) => {
        optionParams.setSelectedKeys(value ?? []);
      },
      onDropdownVisibleChange: (visible) => {
        if (!visible) {
          optionParams.handleSearch(
            optionParams.selectedKeys,
            optionParams.confirm,
            optionParams.dataIndex,
            optionParams.searchProp
          );
        }
      },
    }),
  },
  cash_type_label: {
    searchField: "cash_type",
    component: SelectInput,
    sorter: true,
    options: (optionParams: any) => ({
      mode: "multiple",
      placeholder: "",
      value: optionParams.selectedKeys,
      options: Object.keys(params.cashTypes)?.map((key, index) => ({
        label: params.cashTypes[key],
        value: key,
      })),
      onChange: (value, option) => {
        optionParams.setSelectedKeys(value ?? []);
      },
      onDropdownVisibleChange: (visible) => {
        if (!visible) {
          optionParams.handleSearch(
            optionParams.selectedKeys,
            optionParams.confirm,
            optionParams.dataIndex,
            optionParams.searchProp
          );
        }
      },
    }),
  },
  hits: {
    searchField: "hits",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  hosts: {
    searchField: "hosts",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  regs: {
    searchField: "regs",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  rounds: {
    searchField: "rounds",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  qualified_players: {
    searchField: "qualified_players",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  first_deposits: {
    searchField: "first_deposits",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  deposits: {
    searchField: "deposits",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  withdraws: {
    searchField: "withdraws",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      value: optionParams.selectedKeys[0],
      placeholder: ">=",
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  ng: {
    searchField: "ng",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  comppoints: {
    searchField: "comppoints",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  casino_profit: {
    searchField: "casino_profit",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  partner_income: {
    searchField: "partner_income",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
  payouts: {
    searchField: "payouts",
    component: Input,
    sorter: true,
    options: (optionParams: any) => ({
      placeholder: ">=",
      value: optionParams.selectedKeys[0],
      onChange: (e) =>
        optionParams.setSelectedKeys(e.target.value ? [e.target.value] : []),
      onPressEnter: () =>
        optionParams.handleSearch(
          optionParams.selectedKeys,
          optionParams.confirm,
          optionParams.dataIndex,
          optionParams.searchProp
        ),
    }),
  },
});

export interface AffiliatesReportUserStatusesResponse {
  statuses: any;
  roles: any;
  traffic_types: any;
  cash_types: any;
}

export interface ReportProgramsResponse {
  programs: AffiliateReportProject[];
  program_types: AffiliateReportProgramType[];
}

type AffiliateReportProject = {
  [key: string]: AffiliateReportProgram;
};

type AffiliateReportProgram = {
  program_id: number;
  full_name: string;
};

type AffiliateReportProgramType = {
  [key: string]: string;
};
